class Selector {
    private main

    constructor(selector: string, attribute = 'behaviour') {
        this.main = (thunk: (arg0: string, arg1: string) => string): string =>
            thunk(attribute, selector)
    }

    str = (): string =>
        this.main(
            (attribute: string, selector: string) =>
                `[data-${attribute}="${selector}"]`,
        )

    node = (): HTMLElement | null => document.querySelector(this.str())
    nodeFrom = (el: Element): HTMLElement | null => el.querySelector(this.str())
    nodes = (): NodeListOf<HTMLElement> => document.querySelectorAll(this.str())
    nodesFrom = (el: Element): NodeListOf<HTMLElement> =>
        el.querySelectorAll(this.str())
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const _ = (selector: string, attribute = 'behaviour') => {
    return new Selector(selector, attribute)
}

/**
 *
 * @param {array} els - node array
 * @param {string} className - class name to toggle
 *
 */

export const toggleClasses = (
    els: NodeListOf<HTMLElement>,
    className: string,
): void => {
    Array.from(els).map((el) => {
        el.classList.toggle(className)
    })
}

/**
 *
 * @param {array} els - node array
 * @param {string} className - class name to add
 *
 */

export const addClasses = (
    els: NodeListOf<HTMLElement>,
    className: string,
): void => {
    Array.from(els).map((el) => {
        el.classList.add(className)
    })
}

/**
 *
 * @param {array} els - node array
 * @param {string} className - class name to add
 *
 */

export const removeClasses = (
    els: NodeListOf<HTMLElement>,
    className: string,
): void => {
    Array.from(els).map((el) => {
        el.classList.remove(className)
    })
}
