// Import Sass entry file and SVG sprite.
import '../../ui/stylesheets/application.scss'
import '../../ui/images/sprite.svg'

// Import application config and UI module initialisation.
import { uiInit } from './ui-init'

// No need for polyfills, etc, as TLB site is very simple.
uiInit()

// Run UI module instantition ONCE when each Storybook story has loaded.
const storybookUIinit = (): void => {
    console.log('Storybook custom event fired...')
    uiInit()
}
// 'StorybookLoaded' is an event that is dispatched from Storybook.
window.addEventListener('StorybookLoaded', storybookUIinit, false)
