/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import Wysiwyg from './modules/wysiwyg'
_('wysiwyg', 'element').nodes().forEach((el) => new Wysiwyg(el))
----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-element="wysiwyg"          - wysiwyg container
*/

class Wysiwyg {
    private el: HTMLElement
    private tables: NodeListOf<HTMLTableElement>

    constructor(el: HTMLElement) {
        this.el = el
        this.tables = this.el.querySelectorAll('table')

        this.wrapTables()
    }

    private wrapTables(): void {
        this.tables.forEach((table) => {
            const tableWrapper = document.createElement('div')
            tableWrapper.classList.add('table-wrapper')
            tableWrapper.setAttribute('role', 'region')
            tableWrapper.setAttribute('tabIndex', '0')

            table.parentNode?.insertBefore(tableWrapper, table)
            tableWrapper.appendChild(table)
        })
    }
}
export default Wysiwyg
